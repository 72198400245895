import {
  Document,
  View,
  Text,
  Page,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import optimumLogo from "../../../assets/images/OptimumLogo.png";
import front from "../../../assets/images/Body_front.png";
import back from "../../../assets/images/Body_back.png";
import left from "../../../assets/images/Body_left.jpeg";
import right from "../../../assets/images/Body_right.jpeg";
import { useTranslation } from "react-i18next";
import localfont from "../../../fonts/HeveticaNeue.woff";
export const MyDoc = ({
  data,
  piercingValue,
  waterIntakeAndCoffeeValue,
  sleepValue,
  habitualValue,
  monotonouseValue,
  workdayValue,
  irtProblem,
  eftProblem,
  client,
}) => {
  const { t } = useTranslation();

  const getOrderedArray = (visit) => {
    const desiredOrder = [
      "Decreased hamstringflexibility",
      "Decreased external hip rotation",
      "Decreased internal hip rotation",
      "Decreased hip horisontal adduction",
      "Weak in supine wedgetreatmenttest",
      "Weak in symphysistest",
      "Imbalanced side leg lifts",
      "Uneven lumbar erectors",
      "Weak in sacrumtest",
      "Weak in prone wedgetreatmenttest",
      "Weak popliteus",
      "Weak prone leg lifts",
    ];

    if (
      visit.functionalAnalysys.Pelvic !== "Ok" &&
      visit.functionalAnalysys.PelvicB &&
      visit.functionalAnalysys.PelvicB.length > 0 &&
      visit.functionalAnalysys.PelvicB[0]?.value !== "Ok"
    ) {
      return desiredOrder
        .map((desiredValue) =>
          visit.functionalAnalysys.PelvicB.find(
            (item) => item.value === desiredValue
          )
        )
        .filter(Boolean);
    } else {
      return [];
    }
  };
  Font.register({
    family: "helvetica",
    src: localfont,
  });

  return (
    <Document>
      {data && data?.length > 0
        ? data?.map((currentVisit, key) => (
            <Page key={key} size="A4" wrap style={styles.page}>
              <View fixed style={styles.header}>
                <Text style={styles.headerText}>
                  {t("OptimumTraining Journal")}
                </Text>
                <Image style={styles.headerImage} src={optimumLogo} />
              </View>

              <View style={styles.VisitContainer}>
                <View style={styles.visitHeading}>
                  <Text>
                    {t("Visit")} {currentVisit.visitNo}
                  </Text>
                  <Text>{currentVisit.visitDate}</Text>
                </View>

                {/* daily note */}
                {currentVisit.daily_note !== "" ? (
                  <View wrap={false} style={styles.textRow}>
                    <Text style={styles.textQuestion}>{t("Daily note")}</Text>
                    <View style={styles.textAnswer}>
                      <Text>{t(currentVisit.daily_note)}</Text>
                    </View>
                  </View>
                ) : null}

                {/* pain/functional desc */}
                {currentVisit.painAndFunctionDesc &&
                currentVisit.painAndFunctionDesc.length > 0 &&
                currentVisit.painAndFunctionDesc[0] !== "" ? (
                  <View wrap={true} style={styles.textRow}>
                    <Text style={styles.textQuestion}>
                      {t("Pain/function description")}
                    </Text>
                    <View wrap={true} style={styles.multiAnswerContainer}>
                      {currentVisit.painAndFunctionDesc.map((data) => (
                        <View style={styles.multiAnswer}>
                          <Text>{data}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                ) : null}

                {/* Medication */}
                {currentVisit.Medication !== "" && (
                  <View wrap={false} style={styles.textRow}>
                    <Text style={styles.textQuestion}>
                      {" "}
                      {t("Medication/s")}
                    </Text>
                    <View style={styles.textAnswer}>
                      <Text>{t(currentVisit.Medication)}</Text>
                    </View>
                  </View>
                )}

                {/* functional Analysis */}
                <View style={styles.textRow}>
                  <Text style={styles.textQuestion}>
                    {t("Functional Analysis")}
                  </Text>
                  <View style={styles.multiAnswerContainer}>
                    {currentVisit.functionalAnalysys.posturalText !== "" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Postural deviations")}
                          {" - "}
                          {currentVisit.functionalAnalysys.posturalText}
                        </Text>
                      </View>
                    ) : null}
                    {currentVisit.functionalAnalysys.straightFeet !== "Ok" &&
                    currentVisit.functionalAnalysys.straightFeet !== "" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Standing with straight feet")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.straightFeet)}
                          {currentVisit.functionalAnalysys.straightFeetNote &&
                            ", " +
                              currentVisit.functionalAnalysys.straightFeetNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys?.footImbalances &&
                    currentVisit.functionalAnalysys?.footImbalances !== "" &&
                    currentVisit.functionalAnalysys?.footImbalances !== "No" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Check for foot imbalances?")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys?.footImbalances)}
                          {currentVisit.functionalAnalysys
                            ?.footImbalancesNote &&
                            ", " +
                              currentVisit.functionalAnalysys
                                ?.footImbalancesNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys.pelvicTilt !== "" &&
                    currentVisit.functionalAnalysys.pelvicTilt !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {" "}
                          {t("Standing pelvic tilt")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.pelvicTilt)}
                          {currentVisit.functionalAnalysys.pelvicTiltNote &&
                            ", " +
                              currentVisit.functionalAnalysys.pelvicTiltNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys?.standingLeglift &&
                    currentVisit.functionalAnalysys?.standingLeglift !== "" &&
                    currentVisit.functionalAnalysys?.standingLeglift !==
                      "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Standing leglift")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys?.standingLeglift)}
                          {currentVisit.functionalAnalysys
                            ?.standingLegliftNote &&
                            ", " +
                              currentVisit.functionalAnalysys
                                ?.standingLegliftNote}
                        </Text>
                      </View>
                    ) : null}

                    {(currentVisit.functionalAnalysys.Necksidebend !== "" &&
                      currentVisit.functionalAnalysys.Necksidebend !== "Ok") ||
                    (currentVisit.functionalAnalysys.Neckrotation !== "" &&
                      currentVisit.functionalAnalysys.Neckrotation !== "Ok") ||
                    (currentVisit.functionalAnalysys.TMJ.length > 0 &&
                      currentVisit.functionalAnalysys.TMJ[0] !== "Ok") ? (
                      <>
                        {currentVisit.functionalAnalysys.Necksidebend !== "" &&
                        currentVisit.functionalAnalysys.Necksidebend !==
                          "Ok" ? (
                          <View wrap={false} style={styles.multiAnswer}>
                            <Text>
                              {t("Necksidebend test")}
                              {" - "}
                              {t(currentVisit.functionalAnalysys.Necksidebend)}
                              {currentVisit.functionalAnalysys
                                .NecksidebendNote &&
                                ", " +
                                  currentVisit.functionalAnalysys
                                    .NecksidebendNote}
                            </Text>
                          </View>
                        ) : null}
                        {currentVisit.functionalAnalysys.Neckrotation !== "" &&
                        currentVisit.functionalAnalysys.Neckrotation !==
                          "Ok" ? (
                          <View wrap={false} style={styles.multiAnswer}>
                            <Text>
                              {t("Neckrotation test")}
                              {" - "}
                              {t(currentVisit.functionalAnalysys.Neckrotation)}
                              {currentVisit.functionalAnalysys
                                .NeckrotationNote &&
                                ", " +
                                  currentVisit.functionalAnalysys
                                    .NeckrotationNote}
                            </Text>
                          </View>
                        ) : null}
                        {currentVisit.functionalAnalysys.TMJ.length > 0 &&
                        currentVisit.functionalAnalysys.TMJ[0] !== "Ok" ? (
                          <View wrap={false} style={styles.multiAnswer}>
                            <Text>
                              {t("TMJ test")}
                              {" - "}
                              {currentVisit.functionalAnalysys.TMJ.map(
                                (data, i) => (
                                  <>
                                    {t(data)}
                                    {""}
                                    <span>
                                      {currentVisit.functionalAnalysys &&
                                      currentVisit.functionalAnalysys.TMJ
                                        .length -
                                        1 ===
                                        i
                                        ? ""
                                        : ", "}
                                    </span>
                                  </>
                                )
                              )}
                              {currentVisit.functionalAnalysys.TMJNote &&
                                ", " + currentVisit.functionalAnalysys.TMJNote}
                            </Text>
                          </View>
                        ) : null}
                      </>
                    ) : null}
                    {currentVisit.functionalAnalysys.ShoulderMobility !== "" &&
                    currentVisit.functionalAnalysys.ShoulderMobility !==
                      "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {" "}
                          {t("Shouldermobility")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.ShoulderMobility)}
                          {currentVisit.functionalAnalysys
                            .ShoulderMobilityNote &&
                            ", " +
                              currentVisit.functionalAnalysys
                                .ShoulderMobilityNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys?.externalShoulder &&
                    currentVisit.functionalAnalysys?.externalShoulder !== "" &&
                    currentVisit.functionalAnalysys?.externalShoulder !==
                      "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("External shoulder rotation test")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys?.externalShoulder)}
                          {currentVisit.functionalAnalysys
                            ?.externalShoulderNote &&
                            ", " +
                              currentVisit.functionalAnalysys
                                ?.externalShoulderNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys?.internalShoulder &&
                    currentVisit.functionalAnalysys?.internalShoulder !== "" &&
                    currentVisit.functionalAnalysys?.internalShoulder !==
                      "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Internal shoulder rotation test")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys?.internalShoulder)}
                          {currentVisit.functionalAnalysys
                            ?.internalShoulderNote &&
                            ", " +
                              currentVisit.functionalAnalysys
                                ?.internalShoulderNote}
                        </Text>
                      </View>
                    ) : null}

                    {(currentVisit.functionalAnalysys.Spinal1 !== "" &&
                      currentVisit.functionalAnalysys.Spinal1 !== "Ok") ||
                    (currentVisit.functionalAnalysys.Spinal2 !== "" &&
                      currentVisit.functionalAnalysys.Spinal2 !== "Ok") ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        {currentVisit.functionalAnalysys.Spinal1 !== "" &&
                        currentVisit.functionalAnalysys.Spinal1 !== "Ok" ? (
                          // <View wrap={false} style={styles.multiAnswer}>
                          <Text>
                            {t("Spinal lateral flexion 1")}
                            {" - "}
                            {t(currentVisit.functionalAnalysys.Spinal1)}
                            {currentVisit.functionalAnalysys.Spinal1Note &&
                              ", " +
                                currentVisit.functionalAnalysys.Spinal1Note}
                          </Text>
                        ) : // {/* </View> */}
                        null}
                        {currentVisit.functionalAnalysys.Spinal2 !== "" &&
                        currentVisit.functionalAnalysys.Spinal2 !== "Ok" ? (
                          // <View wrap={false} style={styles.multiAnswer}>
                          <Text>
                            {t("Spinal lateral flexion 2")}
                            {" - "}
                            {t(currentVisit.functionalAnalysys.Spinal2)}
                            {currentVisit.functionalAnalysys.Spinal2Note &&
                              ", " +
                                currentVisit.functionalAnalysys.Spinal2Note}
                          </Text>
                        ) : // </View>
                        null}
                      </View>
                    ) : null}
                    {currentVisit.functionalAnalysys.Thoracic !== "" &&
                    currentVisit.functionalAnalysys.Thoracic !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Thoracic rotation")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.Thoracic)}
                          {currentVisit.functionalAnalysys.ThoracicNote &&
                            ", " + currentVisit.functionalAnalysys.ThoracicNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys?.shoulderRolls &&
                    currentVisit.functionalAnalysys?.shoulderRolls !== "" &&
                    currentVisit.functionalAnalysys?.shoulderRolls !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Shoulderrolls")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys?.shoulderRolls)}
                          {currentVisit.functionalAnalysys?.shoulderRollsNote &&
                            ", " +
                              currentVisit.functionalAnalysys
                                ?.shoulderRollsNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys.HandsBehind !== "" &&
                    currentVisit.functionalAnalysys.HandsBehind !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Hands behind the head")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.HandsBehind)}
                          {currentVisit.functionalAnalysys.HandsBehindNote &&
                            ", " +
                              currentVisit.functionalAnalysys.HandsBehindNote}
                        </Text>
                      </View>
                    ) : null}
                    {currentVisit.functionalAnalysys.CTFunction !== "" &&
                    currentVisit.functionalAnalysys.CTFunction !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("CT-Function")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.CTFunction)}
                          {currentVisit.functionalAnalysys.CTFunctionNote &&
                            ", " +
                              currentVisit.functionalAnalysys.CTFunctionNote}
                        </Text>
                      </View>
                    ) : null}
                    {currentVisit.functionalAnalysys.Breathing.length > 0 &&
                    currentVisit.functionalAnalysys.Breathing[0] !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Breathing test")}
                          {" - "}
                          {currentVisit.functionalAnalysys.Breathing.map(
                            (data, i) => (
                              <>
                                {t(data)}
                                {""}
                                <span>
                                  {(currentVisit.functionalAnalysys &&
                                    currentVisit.functionalAnalysys.Breathing
                                      .length -
                                      1 ===
                                      i) ||
                                  1 >= i
                                    ? ""
                                    : ", "}
                                </span>
                              </>
                            )
                          )}
                          {currentVisit.functionalAnalysys.BreathingNote &&
                            ", " +
                              currentVisit.functionalAnalysys.BreathingNote}
                        </Text>
                      </View>
                    ) : null}
                    {currentVisit.functionalAnalysys.Motor !== "" &&
                    currentVisit.functionalAnalysys.Motor !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {t("Motor Integration test")}
                          {" - "}
                          {t(currentVisit.functionalAnalysys.Motor)}
                          {currentVisit.functionalAnalysys.MotorNote &&
                            ", " + currentVisit.functionalAnalysys.PelvicNote}
                        </Text>
                      </View>
                    ) : null}

                    {currentVisit.functionalAnalysys.Pelvic !== "" &&
                    currentVisit.functionalAnalysys.Pelvic !== "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          Pelvic Rolls
                          {" - "}
                          {t(currentVisit.functionalAnalysys.Pelvic)}
                          {currentVisit.functionalAnalysys.PelvicNote &&
                            ", " + currentVisit.functionalAnalysys.PelvicNote}
                        </Text>
                      </View>
                    ) : null}
                    {currentVisit.functionalAnalysys.Pelvic !== "Ok" &&
                    currentVisit.functionalAnalysys.PelvicB &&
                    currentVisit.functionalAnalysys.PelvicB.length > 0 &&
                    currentVisit.functionalAnalysys.PelvicB[0]?.value !==
                      "Ok" ? (
                      <View wrap={false} style={styles.multiAnswer}>
                        <Text>
                          {"Other tests"}
                          {" - "}
                          {getOrderedArray(currentVisit).map((data, i) => (
                            <>
                              {`${t(data.value)} ${
                                data?.note ? `- (${data?.note})` : ""
                              }`}
                              {""}
                              <span>
                                {currentVisit.functionalAnalysys &&
                                currentVisit.functionalAnalysys.PelvicB.length -
                                  1 >
                                  i
                                  ? ", "
                                  : ""}
                              </span>
                            </>
                          ))}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>

                {/* Other test/s */}
                {currentVisit.functionalAnalysys.otherTest !== undefined &&
                currentVisit.functionalAnalysys.otherTest !== "" ? (
                  <View wrap={false} style={styles.textRow}>
                    <Text style={styles.textQuestion}>{t("Other test/s")}</Text>
                    <View style={styles.textAnswer}>
                      <Text>{currentVisit.functionalAnalysys.otherTest}</Text>
                    </View>
                  </View>
                ) : null}

                {/* Functional assessment */}
                {currentVisit.functionalAssessment !== undefined &&
                currentVisit.functionalAssessment !== "" ? (
                  <View wrap={false} style={styles.textRow}>
                    <Text style={styles.textQuestion}>
                      {t("Functional assessment")}
                    </Text>
                    <View style={styles.textAnswer}>
                      <Text>{currentVisit.functionalAssessment}</Text>
                    </View>
                  </View>
                ) : null}

                {/* Recommendations/Tips given */}
                {currentVisit.RecommendationToClient !== undefined &&
                currentVisit.RecommendationToClient.length > 0 ? (
                  <View wrap={false} style={styles.tableContainer}>
                    <Text style={styles.tableHeading}>
                      {t("Recommendations/Tips given")}
                    </Text>

                    <View style={styles.tableProblemContainer}>
                      {currentVisit.RecommendationToClient !== undefined &&
                      currentVisit.RecommendationToClient.length > 0
                        ? currentVisit.RecommendationToClient.map((item) => (
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(item.name)}

                                {piercingValue &&
                                item.name === piercingValue ? (
                                  currentVisit.piercingNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.piercingNote}
                                    </Text>
                                  ) : null
                                ) : waterIntakeAndCoffeeValue &&
                                  item.name === waterIntakeAndCoffeeValue ? (
                                  currentVisit.waterIntakeAndCoffeeNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.waterIntakeAndCoffeeNote}
                                    </Text>
                                  ) : null
                                ) : sleepValue && item.name === sleepValue ? (
                                  currentVisit.improveSleepNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.improveSleepNote}
                                    </Text>
                                  ) : null
                                ) : habitualValue &&
                                  item.name === habitualValue ? (
                                  currentVisit.habitualNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.habitualNote}
                                    </Text>
                                  ) : null
                                ) : monotonouseValue &&
                                  item.name === monotonouseValue ? (
                                  currentVisit.monotonouseNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.monotonouseNote}
                                    </Text>
                                  ) : null
                                ) : workdayValue &&
                                  item.name === workdayValue ? (
                                  currentVisit.workdayNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.workdayNote}
                                    </Text>
                                  ) : null
                                ) : null}
                              </Text>
                            </View>
                          ))
                        : null}
                    </View>
                  </View>
                ) : null}

                {/* Treatments done */}
                {(currentVisit.PelvicRollsTreatment !== undefined &&
                  currentVisit.PelvicRollsTreatment.length > 0) ||
                (currentVisit.recommendedTreatment !== undefined &&
                  currentVisit.recommendedTreatment.length > 0) ||
                (currentVisit.PosturalChainTreatment !== undefined &&
                  currentVisit.PosturalChainTreatment.length > 0) ||
                (currentVisit.AddressTheNeckTreatment !== undefined &&
                  currentVisit.AddressTheNeckTreatment.length > 0) ||
                (currentVisit.AddressTheTMJTreatment !== undefined &&
                  currentVisit.AddressTheTMJTreatment.length > 0) ||
                (currentVisit.AddressTheFrontalPlaneTreatment !== undefined &&
                  currentVisit.AddressTheFrontalPlaneTreatment.length > 0) ||
                (currentVisit.AddressTheArmsShoulderTreatment !== undefined &&
                  currentVisit.AddressTheArmsShoulderTreatment.length > 0) ||
                (currentVisit.PelvicRollsTreatment !== undefined &&
                  currentVisit.PelvicRollsTreatment.length > 0) ||
                (currentVisit.OtherTreatment !== undefined &&
                  currentVisit.OtherTreatment !== "") ? (
                  <View wrap={false} style={styles.tableContainer}>
                    <Text style={styles.tableHeading}>
                      {t("Treatments done")}
                    </Text>
                    {/* <View style={styles.table}>
                  <Text style={styles.tableHead}>
                    {t("Treatments") + " / " + t("notes")}
                  </Text>
                </View> */}
                    <View style={styles.tableProblemContainer}>
                      {currentVisit.NervousSystemExercise !== undefined &&
                      currentVisit.NervousSystemExercise.length > 0 ? (
                        // <View style={styles.table}>
                        <View style={styles.tableProblemContainerNoBorder}>
                          <Text style={styles.tableProblemNames}>
                            {t("Nervous system integration")}
                            {currentVisit.NervousSystemNote ? (
                              <Text style={styles.tableBodyNoborder}>
                                {" - "}
                                {currentVisit.NervousSystemNote}
                              </Text>
                            ) : null}
                          </Text>
                        </View>
                      ) : // </View>
                      null}
                      {currentVisit.recommendedTreatment !== undefined &&
                      currentVisit.recommendedTreatment.length > 0
                        ? currentVisit.recommendedTreatment.map((name) => (
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(name)}

                                {name === "IRT for injuries/scars/tattoos" ? (
                                  currentVisit.tattoosWithIrtNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.tattoosWithIrtNote}
                                    </Text>
                                  ) : null
                                ) : name === `IRT for ${irtProblem}` ? (
                                  currentVisit.treatWithIrtNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.treatWithIrtNote}
                                    </Text>
                                  ) : null
                                ) : name ===
                                  `EFT/EmotionCode for the ${eftProblem}` ? (
                                  currentVisit.treatTheEftNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.treatTheEftNote}
                                    </Text>
                                  ) : null
                                ) : name === "EFT for stressrelief" ? (
                                  currentVisit.stressReliefNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.stressReliefNote}
                                    </Text>
                                  ) : null
                                ) : null}
                              </Text>
                            </View>
                          ))
                        : null}

                      {currentVisit.OtherTreatment !== undefined &&
                      currentVisit.OtherTreatment !== "" ? (
                        // <View style={styles.table}>
                        <View style={styles.tableProblemContainerNoBorder}>
                          <Text style={styles.tableProblemNames}>
                            {t("other treatment")}
                            <Text style={styles.tableBodyNoborder}>
                              {" - "}
                              {currentVisit.OtherTreatment}
                            </Text>
                          </Text>

                          {/* </View> */}
                        </View>
                      ) : null}

                      {currentVisit.PosturalChainTreatment !== undefined &&
                      currentVisit.PosturalChainTreatment.length > 0
                        ? currentVisit.PosturalChainTreatment.map((name) => (
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(name)}

                                {currentVisit.NLNote !== undefined &&
                                name === "NL-points" ? (
                                  currentVisit.NLNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.NLNote}
                                    </Text>
                                  ) : null
                                ) : name === "K1-K27" ? (
                                  currentVisit.K1Note ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.K1Note}
                                    </Text>
                                  ) : null
                                ) : name === "FM iliopsoas" ? (
                                  currentVisit.FMNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.FMNote}
                                    </Text>
                                  ) : null
                                ) : null}
                              </Text>
                            </View>
                          ))
                        : null}
                      {currentVisit.MotorExercise !== undefined &&
                      currentVisit.MotorExercise.length > 0 ? (
                        // <View style={styles.table}>
                        <View style={styles.tableProblemContainerNoBorder}>
                          <Text style={styles.tableProblemNames}>
                            {t("Motor Integration")}
                            {currentVisit.motorIntegrationNote ? (
                              <Text style={styles.tableBodyNoborder}>
                                {" - "}
                                {currentVisit.motorIntegrationNote}
                              </Text>
                            ) : null}
                          </Text>
                        </View>
                      ) : // </View>
                      null}

                      {currentVisit.AddressTheNeckTreatment !== undefined &&
                      currentVisit.AddressTheNeckTreatment.length > 0
                        ? currentVisit.AddressTheNeckTreatment.map((name) => (
                            // <View style={styles.table}>
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(name)}
                                {name === "Neckprotocol" ? (
                                  currentVisit.NeckprotocolNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.NeckprotocolNote}
                                    </Text>
                                  ) : null
                                ) : (
                                  ""
                                )}
                              </Text>
                            </View>
                            // </View>
                          ))
                        : null}

                      {currentVisit.TMJExercise !== undefined &&
                      currentVisit.TMJExercise.length > 0 ? (
                        // <View style={styles.table}>
                        <View style={styles.tableProblemContainerNoBorder}>
                          <Text style={styles.tableProblemNames}>
                            {t("TMJ-protocol")}
                            {currentVisit.TMJprotocolNote ? (
                              <Text style={styles.tableBodyNoborder}>
                                {" - "}
                                {currentVisit.TMJprotocolNote}
                              </Text>
                            ) : null}
                          </Text>
                        </View>
                      ) : // </View>
                      null}
                      {currentVisit.AddressTheTMJTreatment !== undefined &&
                      currentVisit.AddressTheTMJTreatment.length > 0
                        ? currentVisit.AddressTheTMJTreatment.map((name) => (
                            // <View style={styles.table}>
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {name === "EFT/EmotionCode for the TMJ"
                                  ? t("EFT/OptimumMind for the TMJ")
                                  : null}
                                {name === "EFT/EmotionCode for the TMJ" ? (
                                  currentVisit.EFTEmotionCodeNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.EFTEmotionCodeNote}
                                    </Text>
                                  ) : null
                                ) : (
                                  ""
                                )}
                              </Text>
                            </View>
                            // </View>
                          ))
                        : null}

                      {currentVisit.AddressTheFrontalPlaneTreatment !==
                        undefined &&
                      currentVisit.AddressTheFrontalPlaneTreatment.length > 0
                        ? currentVisit.AddressTheFrontalPlaneTreatment.map(
                            (name) => (
                              // <View style={styles.table}>
                              <View
                                style={styles.tableProblemContainerNoBorder}
                              >
                                <Text style={styles.tableProblemNames}>
                                  {t(name)}
                                  {name === "FM diaphragma" ? (
                                    currentVisit.FMDiaphragmNote ? (
                                      <Text style={styles.tableBodyNoborder}>
                                        {" - "}
                                        {currentVisit.FMDiaphragmNote}
                                      </Text>
                                    ) : null
                                  ) : (
                                    ""
                                  )}
                                </Text>
                              </View>
                              // </View>
                            )
                          )
                        : null}

                      {currentVisit.AddressTheArmsShoulderTreatment !==
                        undefined &&
                      currentVisit.AddressTheArmsShoulderTreatment.length > 0
                        ? currentVisit.AddressTheArmsShoulderTreatment.map(
                            (name) => (
                              // <View style={styles.table}>
                              <View
                                style={styles.tableProblemContainerNoBorder}
                              >
                                <Text style={styles.tableProblemNames}>
                                  {t(name)}
                                  {name === "Shoulderprotocol" ? (
                                    currentVisit.ShoulderProtocolNote ? (
                                      <Text style={styles.tableBodyNoborder}>
                                        {" - "}
                                        {currentVisit.ShoulderProtocolNote}
                                      </Text>
                                    ) : null
                                  ) : (
                                    ""
                                  )}
                                </Text>
                              </View>
                              // </View>
                            )
                          )
                        : null}

                      {currentVisit.PelvicRollsTreatment !== undefined &&
                      currentVisit.PelvicRollsTreatment.length > 0
                        ? currentVisit.PelvicRollsTreatment.map((name) => (
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(name)}

                                {name === "Wedge treatment prone" ? (
                                  currentVisit.WedgeProneNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.WedgeProneNote}
                                    </Text>
                                  ) : null
                                ) : name === "Sacrum Treatment" ? (
                                  currentVisit.SacrumNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.SacrumNote}
                                    </Text>
                                  ) : null
                                ) : name === "Wedge treatment supine" ? (
                                  currentVisit.WedgeSupineNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.WedgeSupineNote}
                                    </Text>
                                  ) : null
                                ) : currentVisit.SysphysisNote ? (
                                  <Text style={styles.tableBodyNoborder}>
                                    {" - "}
                                    {currentVisit.SysphysisNote}
                                  </Text>
                                ) : null}
                              </Text>
                            </View>
                          ))
                        : null}

                      {currentVisit.CTFlexionTreatment !== undefined &&
                      currentVisit.CTFlexionTreatment.length > 0
                        ? currentVisit.CTFlexionTreatment.map((name) => (
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(name)}{" "}
                                {currentVisit.CTJunctionNote ? (
                                  <Text style={styles.tableBodyNoborder}>
                                    {" - "}
                                    {currentVisit.CTJunctionNote}
                                  </Text>
                                ) : null}
                              </Text>
                            </View>
                          ))
                        : null}
                    </View>
                  </View>
                ) : null}

                {/* Exercises given */}
                {currentVisit.exercisesGiven !== undefined &&
                currentVisit.exercisesGiven.length > 0 ? (
                  <View wrap={false} style={styles.tableContainer}>
                    <View style={styles.tableHeading}>
                      <Text>{t("Exercises given")}</Text>
                    </View>
                    {currentVisit.AddressTheLegsAndHipsExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address the legs and hips")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressTheLegsAndHipsExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.PosturalChainExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Engage the postural chain")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.PosturalChainExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.AddressTheFrontalPlaneExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address the frontalplane")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressTheFrontalPlaneExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.AddressTheArmsShoulderExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address the arms / shoulders")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressTheArmsShoulderExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.AddressTheSagittalPlaneExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address the sagittalplane")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressTheSagittalPlaneExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.AddressThoracicRotationExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address thoracic rotations")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressThoracicRotationExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.AddressErectorImbalancesExercise &&
                    currentVisit.AddressErectorImbalancesExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address erector Imbalances")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressErectorImbalancesExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.PelvicRollsExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Pelvic Rolls")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.PelvicRollsExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.AddressCTFlexionExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Address CT-flexion")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AddressCTFlexionExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.SDChainExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Strain Distribution Chain (SD-chain)")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.SDChainExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.StabilizerExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t(
                            "Strengthen the posturalchain and secondary stabilizers"
                          )}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.StabilizerExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.OwnBodyWeightExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t(
                            "Strengthen the whole body with ownbodyweight exercises"
                          )}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.OwnBodyWeightExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.GYMExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Strengthen the whole body with gymexercises")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.GYMExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {currentVisit.FinishingExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Finishing exercises")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.FinishingExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                  </View>
                ) : null}

                {/* Daily treatments/exercises */}
                {currentVisit.AllDailyExercise !== undefined &&
                currentVisit.AllDailyExercise.length > 0 ? (
                  <View wrap={false} style={styles.tableContainer}>
                    <Text style={styles.tableHeading}>
                      {t("Daily treatments/exercises")}
                    </Text>

                    {currentVisit.AllDailyExercise.length > 0 ? (
                      <View wrap={false} style={styles.textRow}>
                        <Text style={styles.textQuestion}>
                          {t("Daily exercises")}
                        </Text>
                        <View style={styles.textAnswer}>
                          <Text>
                            {currentVisit.AllDailyExercise.map(
                              (data) =>
                                `${data.name} ${
                                  data.exerciseComment
                                    ? `(${data.exerciseComment})`
                                    : ""
                                }`
                            ).join(", ")}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                  </View>
                ) : null}

                {/* Postural/Functional recommendations given */}
                {currentVisit.AwarenessRecommendation !== undefined &&
                currentVisit.AwarenessRecommendation.length > 0 ? (
                  <View wrap={false} style={styles.tableContainer}>
                    <Text style={styles.tableHeading}>
                      {t("Postural/Functional recommendations given")}
                    </Text>

                    <View style={styles.tableProblemContainer}>
                      {currentVisit.AwarenessRecommendation !== undefined &&
                      currentVisit.AwarenessRecommendation.length > 0
                        ? currentVisit.AwarenessRecommendation.map((item) => (
                            <View style={styles.tableProblemContainerNoBorder}>
                              <Text style={styles.tableProblemNames}>
                                {t(item.name)}
                                {item.name === "Standing with straight feet" ? (
                                  currentVisit.straightFeetNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.straightFeetNote}
                                    </Text>
                                  ) : null
                                ) : item.name === "Standing angel wings" ? (
                                  currentVisit?.standingWingsNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit?.standingWingsNote}
                                    </Text>
                                  ) : null
                                ) : item.name ===
                                  "Bending from waist to pick up things" ? (
                                  currentVisit.bendingNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.bendingNote}
                                    </Text>
                                  ) : null
                                ) : item.name ===
                                  "Sitting with good posture" ? (
                                  currentVisit.goodPostureNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.goodPostureNote}
                                    </Text>
                                  ) : null
                                ) : item.name === "Daily squatting" ? (
                                  currentVisit.squattingNote ? (
                                    <Text style={styles.tableBodyNoborder}>
                                      {" - "}
                                      {currentVisit.squattingNote}
                                    </Text>
                                  ) : null
                                ) : null}
                              </Text>
                            </View>
                          ))
                        : null}
                    </View>
                  </View>
                ) : null}
              </View>
            </Page>
          ))
        : null}
      {client?.beforeTreatmentPic && client?.afterTreatmentPic ? (
        <>
          <Page style={styles.page}>
            <View style={styles.VisitContainer}>
              <Text style={styles.ImageHeader}>{t("Front")}</Text>
              <View style={styles.treatmentContainer}>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.beforeTreatmentPic[0]?.image ?? front}
                  />
                  <Text style={styles.treatmentImageText}>{t("Before")}</Text>
                </View>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.afterTreatmentPic[0]?.image ?? front}
                  />
                  <Text style={styles.treatmentImageText}>{t("After")}</Text>
                </View>
              </View>
            </View>
            <View style={styles.VisitContainer}>
              <Text style={styles.ImageHeader}>{t("Backside")}</Text>
              <View style={styles.treatmentContainer}>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.beforeTreatmentPic[1]?.image ?? back}
                  />
                  <Text style={styles.treatmentImageText}>{t("Before")}</Text>
                </View>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.afterTreatmentPic[1]?.image ?? back}
                  />
                  <Text style={styles.treatmentImageText}>{t("After")}</Text>
                </View>
              </View>
            </View>
          </Page>
          <Page style={styles.page}>
            <View style={styles.VisitContainer}>
              <Text style={styles.ImageHeader}>{t("Left")}</Text>
              <View style={styles.treatmentContainer}>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.beforeTreatmentPic[2]?.image ?? left}
                  />
                  <Text style={styles.treatmentImageText}>{t("Before")}</Text>
                </View>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.afterTreatmentPic[2]?.image ?? left}
                  />
                  <Text style={styles.treatmentImageText}>{t("After")}</Text>
                </View>
              </View>
            </View>
            <View style={styles.VisitContainer}>
              <Text style={styles.ImageHeader}>{t("Right")}</Text>
              <View style={styles.treatmentContainer}>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.beforeTreatmentPic[3]?.image ?? right}
                  />
                  <Text style={styles.treatmentImageText}>{t("Before")}</Text>
                </View>
                <View style={styles.treatmentImageContainer}>
                  <Image
                    style={styles.treatmentImage}
                    src={client?.afterTreatmentPic[3]?.image ?? right}
                  />
                  <Text style={styles.treatmentImageText}>{t("After")}</Text>
                </View>
              </View>
            </View>
          </Page>
        </>
      ) : null}
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    display: "flex",
    alignItems: "center",
    paddingHorizontal: 40,
    paddingVertical: 40,
    // fontFamily: "helvetica",
  },
  header: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  headerText: {
    fontSize: 20,
    fontFamily: "helvetica",
  },
  treatmentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  treatmentImageContainer: {
    width: "100%",
    display: "flex",
    borderWidth: 1,
    borderColor: "lightgray",
    marginBottom: 10,
  },
  treatmentImageText: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#eaf5fc",
  },
  treatmentImage: {
    height: 280,
    width: "100%",
    objectFit: "contain",
  },
  headerImage: {
    height: 62,
  },
  ImageHeader: {
    fontSize: 16,
    padding: 10,
    textAlign: "center",
    backgroundColor: "#eaf5fc",
  },
  VisitContainer: {
    borderTopWidth: 1,
    borderColor: "lightgray",
    width: "90%",
  },
  visitHeading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 15,
    letterSpacing: 0.5,
    border: "1px solid lightgray",
    padding: 10,
    fontFamily: "helvetica",
  },
  textRow: {
    paddingVertical: 7,
    paddingHorizontal: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid lightgray",
    fontFamily: "helvetica",
  },
  textRow2: {
    paddingVertical: 7,
    paddingHorizontal: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "1px solid lightgray",
    fontFamily: "helvetica",
  },
  textQuestion: {
    width: "25%",
    fontSize: 10,
    paddingTop: 8,
    fontFamily: "helvetica",
  },
  textQuestion2: {
    width: "100%",
    fontSize: 12,
    paddingTop: 8,
    fontFamily: "helvetica",
  },
  textQuestion3: {
    width: "100%",
    fontSize: 10,
    paddingTop: 8,
    paddingBottom: 4,
  },
  textAnswer: {
    width: "73%",
    fontSize: 10,
    paddingVertical: 8,
    paddingHorizontal: 5,
    border: "1px solid lightgray",
    borderRadius: 4,
    color: "#595a5c",
    fontFamily: "helvetica",
  },
  textAnswer2: {
    width: "100%",
    fontSize: 10,
    paddingVertical: 8,
    paddingHorizontal: 5,
    border: "1px solid lightgray",
    borderRadius: 4,
    color: "#595a5c",
    fontFamily: "helvetica",
  },
  multiAnswerContainer: {
    width: "73%",
    display: "flex",
    flexDirection: "column",
  },
  multiAnswer: {
    width: "100%",
    fontSize: 10,
    paddingVertical: 8,
    paddingHorizontal: 5,
    border: "1px solid lightgray",
    borderRadius: 4,
    marginBottom: 2,
    color: "#595a5c",
  },
  tableContainer: {
    paddingVertical: 5,
    paddingHorizontal: 5,
    border: "1px solid lightgray",
    marginTop: 10,
  },
  tableHeading: {
    fontSize: 11.5,
    padding: 5,
    fontFamily: "helvetica",
  },
  table: {
    display: "flex",
    // flexDirection: "row",
    border: "1px solid lightgray",
    width: "100%",
    padding: 5,
  },
  tableProblemContainer: {
    // padding: 5,
    // border: "1px solid lightgray",
  },
  tableProblemContainerNoBorder: {
    flexDirection: "row",
    padding: 5,
    border: "1px solid lightgray",
    borderRadius: 4,
    marginVertical: 2,
  },
  tableProblemNames: {
    padding: 5,
    fontSize: 10,
    lineHeight: 1.3,
    fontFamily: "helvetica",
  },
  tableHead: {
    marginTop: 5,
    padding: 10,
    border: "1px solid lightgray",
    width: "100%",
    textAlign: "center",
    fontSize: 10,
  },
  tableHeadInner: {
    paddingVertical: 7,
    width: "100%",
    fontSize: 10,
  },
  tableBody: {
    padding: 5,
    border: "1px solid lightgray",
    width: "100%",
    textAlign: "left",
    fontSize: 10,
    fontWeight: "light",
    borderRadius: 4,
    color: "#595a5c",
  },
  tableBodyNoborder: {
    paddingVertical: 5,
    fontSize: 10,
    fontWeight: "light",
    fontFamily: "helvetica",
    color: "#595a5c",
  },
  exercisesGivenList: {
    padding: 10,
    border: "1px solid lightgray",
    width: "100%",
    textAlign: "left",
    fontSize: 10,
    fontWeight: "light",
    borderRadius: 4,
    color: "#595a5c",
  },
});
